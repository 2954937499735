import { render, staticRenderFns } from "./SavedSearchesAsPdf.vue?vue&type=template&id=f6184d5a&scoped=true"
import script from "./SavedSearchesAsPdf.vue?vue&type=script&lang=js"
export * from "./SavedSearchesAsPdf.vue?vue&type=script&lang=js"
import style0 from "./SavedSearchesAsPdf.vue?vue&type=style&index=0&id=f6184d5a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6184d5a",
  null
  
)

export default component.exports