<!--
  Form used to save, add and edit lists
-->

<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="768"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title>
          <v-container class="ma-0 pa-0">
            <v-row>
              <v-col class="d-flex mt-n2">
                <div
                  class="d-inline-block align-self-start text-subtitle-1"
                  style="width: 80%"
                >
                  {{ $t('account.settings')}}
                </div>
                <div
                  class="d-inline-block text-right align-self-start"
                  style="width: 20%"
                >
                  <v-icon
                    class="mt-n2 mr-n2"
                    @click="hideDialog()"
                  >
                    mdi-close
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab>
            {{ $t('account.email') }} &amp; {{ $t('account.notifications') }}
          </v-tab>
          <v-tab>
            {{ $t('account.view') }}
          </v-tab>
          <v-tab>
            {{ $t('account.export')}}
          </v-tab>
        </v-tabs>

        <v-card-text
          class="pb-0"
        >
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <account-preferences-form-email
                :userPreferences='data.userPreferences'
                :emailPreferences='data.emailPreferences'
                v-on:update-save-status="updateSaveStatus"
              />
            </v-tab-item>
            <v-tab-item>
              <account-preferences-form-general
                :userPreferences='data.userPreferences'
                :organizationPreferences='data.organizationPreferences'
                v-on:hide-dialog="hideDialog();"
                v-on:update-save-status="updateSaveStatus"
              />
            </v-tab-item>
            <v-tab-item>
              <account-preferences-export
                v-on:hide-dialog="hideDialog();"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <div class="text-end ma-4">
          <v-progress-circular
            v-if="saveState === 'loading'"
            indeterminate
            class="mr-4"
          ></v-progress-circular>
          <span
            v-if="saveState === 'saved'"
            class="mr-4"
          > {{ $t('generic.saved') }}
            <v-icon >mdi-check</v-icon>
          </span>
          <v-btn
            color="teal lighten-1"
            class="white--text"
            @click="hideDialog()"
          >
          {{$t('generic.ok')}}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { hideDialog } from '@/helpers/dialogHelper';

import AccountPreferencesFormGeneral from './AccountPreferencesFormGeneral.vue';
import AccountPreferencesFormEmail from './AccountPreferencesFormEmail.vue';
import AccountPreferencesExport from './AccountPreferencesExport.vue';


export default {
  name: 'AccountPreferencesForm',

  components: {
    AccountPreferencesFormGeneral,
    AccountPreferencesFormEmail,
    AccountPreferencesExport,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          userPreferences: {},
          organizationPreferences: {},
          emailPreferences: {},
        };
      },
    },
  },

  data() {
    return {
      dialog: true,
      tab: null,
      saveState: null,
    };
  },

  computed: {
    ...mapGetters({
      document: 'nlDocument',
      authHasModuleAccess: 'authHasModuleAccess',
    }),
  },

  watch: {
    tab() {
      this.saveState = null;
    },
  },

  methods: {
    hideDialog,
    updateSaveStatus(value, immediate = false) {
      if (value === 'saved' || value === null) {
        if (immediate) {
          this.saveState = value;
        } else {
          // Add a little delay so we can actually see it loading.
          // This feels better than just a quick flash in the UI.
          setTimeout(() => {
            this.saveState = value;
          }, 500);
        }
      } else {
        this.saveState = value;
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-card {
  // Hack to make the v-card not clip on the sides
  max-width: 99%;
  margin-left: auto;
  margin-right: auto;

  .v-card__subtitle {
    padding-left: 0;
    font-size: 1.3em;
  }
}
</style>
