import Vue from 'vue';
import store from '../store';
import vuetify from '../plugins/vuetify';
import router from '../router';
import i18n from '../i18n';

function createDialogContainer() {
  const container = document.createElement('div');
  container.setAttribute('id', 'dialog-container');
  return container;
}

function hideDialog() {
  // https://stackoverflow.com/questions/40445125/how-can-component-delete-itself-in-vue-2-0
  this.$destroy();
  // const { parentNode } = this.$el;
  this.$el.parentNode.removeChild(this.$el);

  // const container = createDialogContainer();
  // parentNode.appendChild(container);
}

function showDialog(component, data) {
  const container = createDialogContainer();
  document.getElementById('app').appendChild(container);

  // https://stackoverflow.com/questions/53233331/dynamically-instantiating-a-component-in-vue-js
  const ComponentClass = Vue.extend(component);
  const instance = new ComponentClass({
    vuetify,
    store,
    router,
    i18n,
    propsData: {
      data,
    },
  });
  instance.$mount('#dialog-container');
}

export {
  hideDialog,
  showDialog,
};
